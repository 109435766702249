@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-4xl;
  }
  h2 {
    @apply text-3xl;
  }
  h3 {
    @apply text-2xl;
  }
  h4 {
    @apply text-lg;
  }
  p {
    @apply font-light;
  }
}

body {
  margin: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #202442;
  background-color: #f9f9fc;
}

th {
  background-color: #dedefa;
  color: #fb0000;
}
th,
td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
tr:nth-child(even) {
  background-color: #f1f4f6;
}
